<template>
  <Toast />
  <Dialog
    header=" "
    v-model:visible="showDialog"
    :modal="true"
    position="top"
    :style="{ maxWidth: '482px', marginTop: '70px' }"
  >
    <div class="flex mb-4 flex-column align-items-center">
      <div class="mt-2 mb-4">
        <img src="../../../public/layout/images/priti/check.svg" alt="Checklist" />
      </div>
      <h2 class="tw-font-extrabold tw-text-3xl tw-mb-6">
        Pendaftaran berhasil!
      </h2>
      <div class="text-center">
        Mohon menunggu kabar dari admin kami melalui email untuk mengkonfirmasi
        pendaftaran anda
      </div>
    </div>

    <template #footer>
      <div class="flex mb-4 flex-column align-items-center">
        <Button
          label="Kembali ke halaman utama"
          class="tw-bg-blue-primary hover:tw-bg-blue-light"
          :style="{ width: '80%', height: '60px' }"
          @click="backToLandingPage"
        />
      </div>
    </template>
  </Dialog>
  
  <LandingLayout>
    <template #header>
      <div class="tw-w-full tw-bg-blue-primary tw-overflow-hidden tw-flex tw-justify-center tw-z-10"
        id="hero">
        <Navbar />
      </div>
    </template>

    <div class="tw-relative tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-64 md:tw--translate-y-72 lg:tw--translate-y-108 tw-w-11/12 md:tw-w-2/3 lg:tw-w-3/4 tw-flex tw-flex-col tw-justify-center">
      <div class="tw-mb-8">
        <h1 class="tw-text-white tw-text-4xl md:tw-text-5xl lg:tw-text-6xl tw-font-bold tw-text-center tw-mb-8">
          Daftar sebagai <br/> mitra salon Salonial
        </h1>
        <div class="tw-text-xl md:tw-text-3xl tw-text-center tw-text-white">
          Kembangkan bisnis salonmu bersama kami,<br />
          perbanyak pemasukan bisnismu bersama Salonial!
        </div>
      </div>

      <div class="tw-w-full tw-flex tw-justify-center tw-items-center">
        <div class="tw-w-full tw-bg-white tw-rounded-2xl tw-p-16 tw-shadow-lg">
          <FormKit
            type="form"
            id="registration"
            :actions="false"
            :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
            #default="{ state: { valid } }"
            @submit="register"
          >
            <FormKit
              name="salon_name"
              type="text"
              label="Nama Salon"
              placeholder="Masukkan nama salon"
              validation="required"
              validation-visibility="dirty"
              :classes="defaultFormikClass" />

            <FormKit
              name="description"
              type="text"
              label="Deskripsi"
              placeholder="Masukkan deskripsi salon"
              validation="required"
              validation-visibility="dirty"
              :classes="defaultFormikClass" />

            <FormKit
              type="tel"
              name="phone_number"
              label="Nomor Whatsapp Admin"
              placeholder="Masukkan nomor admin terhubung ke WA"
              validation="required|number|length:10,13"
              validation-visibility="dirty"
              :classes="defaultFormikClass" />

            <FormKit
              type="email"
              name="email"
              label="Email"
              placeholder="example@email.com"
              validation="required|email"
              validation-visibility="dirty"
              :classes="defaultFormikClass"
              help="Masukkan email yang aktif" />

            <FormKit
              name="address"
              type="text"
              label="Alamat"
              placeholder="Masukkan alamat salon"
              validation="required"
              validation-visibility="dirty"
              :classes="defaultFormikClass" />

            <!-- <FormKit
              type="text"
              name="coverage_area"
              label="Cakupan Wilayah dalam satuan Km."
              placeholder="Cakupan Wilayah dalam satuan Km."
              validation="number"
              help="Cakupan Wilayah dalam satuan KM. Masukkan 0 jika tidak memiliki layanan pesan ke rumah/kantor"
              validation-visibility="dirty"
              :classes="defaultFormikClass" /> -->

             <FormKit
              name="province_id"
              type="select"
              label="Provinsi"
              validation="required"
              placeholder="Pilih Provinsi"
              v-model="selectedProvince"
              :classes="defaultFormikClass"
              @input="onProvinceChange"
              :options="provinces"
            >
              <template #input="context">
                <div class="p-fluid">
                  <Dropdown
                    v-model="context.value"
                    @change="(e) => {
                      context.node.input(e.value);
                    }"
                    :options="context.options"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="context.placeholder"
                    :filter="true"
                    filterPlaceholder="Cari Provinsi"
                  >
                    <template #option="optionContext">
                      <option
                        v-if="!optionContext.option.attrs"
                        :key="optionContext.index"
                        :value="optionContext.option.value"
                      >
                        {{ optionContext.option.label }}
                      </option>
                      <option
                        v-else
                        :key="optionContext.index"
                        :disabled="true"
                      >
                        Not Selected
                      </option>
                    </template>
                  </Dropdown>
                </div>
              </template>
            </FormKit> 
            <FormKit
              name="city_id"
              type="select"
              label="Kota"
              validation="required"
              placeholder="Pilih Kota"
              v-model="selectedCity"
              :classes="defaultFormikClass"
              @input="onCityChange"
              :options="cities"
            >
              <template #input="context">
                <div class="p-fluid">
                  <Dropdown
                    v-model="context.value"
                    @change="(e) => {
                      context.node.input(e.value);
                    }"
                    :options="context.options"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="context.placeholder"
                    :filter="true"
                    filterPlaceholder="Cari Kota"
                    :disabled="!selectedProvince"
                  >
                    <template #option="optionContext">
                      <option
                        v-if="!optionContext.option.attrs"
                        :key="optionContext.index"
                        :value="optionContext.option.value"
                      >
                        {{ optionContext.option.label }}
                      </option>
                      <option
                        v-else
                        :key="optionContext.index"
                        :disabled="true"
                      >
                        Not Selected
                      </option>
                    </template>
                  </Dropdown>
                </div>
              </template>
            </FormKit>

            <FormKit
              name="district_id"
              type="select"
              label="Kecamatan"
              validation="required"
              placeholder="Pilih Kecamatan"
              v-model="selectedDistrict"
              :classes="defaultFormikClass"
              :options="districts"
            >
              <template #input="context">
                <div class="p-fluid">
                  <Dropdown
                    v-model="context.value"
                    @change="(e) => {
                        context.node.input(e.value);
                      }"
                    :options="context.options"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="context.placeholder"
                    :filter="true"
                    filterPlaceholder="Cari Kecamatan"
                    :disabled="!selectedCity"
                  >
                    <template #option="optionContext">
                      <option
                        v-if="!optionContext.option.attrs"
                        :key="optionContext.index"
                        :value="optionContext.option.value"
                      >
                        {{ optionContext.option.label }}
                      </option>
                      <option
                        v-else
                        :key="optionContext.index"
                        :disabled="true"
                      >
                        Not Selected
                      </option>
                    </template>
                  </Dropdown>
                </div>
              </template>
            </FormKit>


            <div class="my-3">
              <label for="images" class="tw-text-lg tw-font-bold">
                Foto Salon
              </label>
              <UploadMedia @media="uploadImage" />
            </div>

            <div>
              <label for="position" class="tw-text-lg tw-font-bold">
                Lokasi
              </label>
              <div class="formkit-help tw-text-sm tw-text-gray-400">
                Masukkan alamat pada kolom Search. Geser pin merah atau klik sesuai lokasi salon Anda untuk menempatkan alamat yang benar
              </div>
              <GMapMap
                :center="center"
                :zoom="zoom"
                :maxZoom="18"
                map-type-id="terrain"
                class="tw-mt-4 tw-w-full tw-relative"
                style="height: 500px"
                @click="onMarkerChange"
              >
                <GMapAutocomplete
                  placeholder="Search..."
                  @place_changed="setPlace"
                  class="input-map">
                </GMapAutocomplete>
                <GMapCluster>
                  <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="updateCenter(m.position)"
                  />
                </GMapCluster>
              </GMapMap>
            </div>

            <div class="form-group mt-3">
              <vue-recaptcha
                class="recaptcha"
                ref="recaptcha"
                :sitekey="sitekey"
                :loadRecaptchaScript="true"
                @verify="onVerifyCapthca"
                @expired="onExpiredCapthca">
              </vue-recaptcha>
            </div>

            <div class="tw-mt-5">
              <div class="field-checkbox mb-0">
                <Checkbox id="checkOption1" :binary="true" v-model="isAgree"/>
                <label for="checkOption1">
                  Saya telah membaca dan menyetujui
                  <span class="tw-font-bold">
                    <a href="https://salonial.com/syarat-dan-ketentuan/" target="_blank">
                      syarat dan ketentuan
                    </a>
                  </span>
                  serta memahami
                  <span class="tw-font-bold">
                    <a href="https://salonial.com/kebijakan-privasi/" target="_blank">
                      kebijakan privasi
                    </a>
                  </span>
                  yang berlaku.
                </label>
              </div>
            </div>

            <div class="tw-flex tw-justify-center tw-mt-12">
              <Button
                v-if="!isLoading"
                label="Kirim"
                class="px-6 p-button-info"
                @click="submitRegisterForm"
                :disabled="!isDataValid(valid)" />

              <ProgressSpinner
                v-if="isLoading"
                stroke-width="5"
                animation-duration="2s" />
            </div>
          </FormKit>
        </div>
      </div>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from '@/layouts/LandingLayout';
import Navbar from '@/components/Landing/Navbar';
import UploadMedia from '@/components/uploader/UploadMedia';
import { useGeoLocation, useLocationDropdown } from '@/composables/location';
import { useRegisterSalon } from '@/composables/salon';
import { ref } from 'vue';
// import { useRouter } from 'vue-router';
import { submitForm } from '@formkit/vue';
import { useToast } from 'primevue/usetoast';
import { defaultFormikClass } from '@/constants/formik';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'Register',
  components: { Navbar, LandingLayout, UploadMedia, VueRecaptcha },
  data() {
    return {
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
    }
  },
  setup() {
    // const router = useRouter();
    const toast = useToast();

    const locationDropdown = useLocationDropdown();
    const geoLocation = useGeoLocation();
    const registerSalon = useRegisterSalon();

    const media = ref(null);
    const mediaSelected = ref(false);
    const isLoading = ref(false);
    const showDialog = ref(false);

    const captcha_resp = ref('');
    const isAgree = ref(false);
    const zoom = ref(10);

    const recaptcha = ref(null);

    const uploadImage = (newMedia) => {
      mediaSelected.value = false;
      media.value = newMedia;
      if (newMedia.length > 0) mediaSelected.value = true;
    };

    const backToLandingPage = () => {
      window.location = 'https://salonial.com';
    };

    const submitRegisterForm = () => {
      let lat = geoLocation.lat.value
      let lng = geoLocation.lng.value
      if (!lat && !lng) {
        toast.add({
          severity: 'error',
          summary: 'Registrasi Gagal',
          detail: 'Silahkan klik lokasi Salon di Map',
          life: 3000,
        });
        return;
      }
      
      submitForm('registration');
      onExpiredCapthca();
      

    };

    const isDataValid = (formkitValid) => {
      return (
        formkitValid &&
        geoLocation.lat.value &&
        geoLocation.lng.value &&
        mediaSelected.value &&
        // captcha_resp.value &&
        isAgree.value
      );
    };

    const buildForm = (formkitData) => {
      const form = new FormData();
      Object.keys(formkitData).forEach((key) => {
        form.append(key, formkitData[key]);
      });

      form.append('lat', geoLocation.lat.value);
      form.append('long', geoLocation.lng.value);
      form.append('g-recaptcha-response', captcha_resp.value);

      media.value.forEach((data, idx) => {
        let name = 'images';
        if (idx === 0) name = 'image';
        form.append(name, data.file);
      });
      return form;
    };

    const register = async (data) => {
      isLoading.value = true;
      try {
        const formData = buildForm(data);
        const response = await registerSalon.salonService.registerSalon(
          formData
        );
        const message = response.data.message

        isLoading.value = false;
        if (response.data.code !== 200) {
          toast.add({
            severity: 'error',
            summary: 'Registrasi Gagal',
            detail: message,
            life: 3000,
          });
          return;
        }
        toast.add({
          severity: 'success',
          summary: 'Registrasi Berhasil',
          detail: 'Salon berhasil terdaftar',
          life: 3000,
        });

        showDialog.value = true;
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: 'Registrasi Gagal',
          detail: e.response.data.message,
          life: 3000,
        });
      }
    };

    const onVerifyCapthca = (recaptchaToken) => {
      captcha_resp.value = recaptchaToken;
    }

    const onExpiredCapthca = () => {
      captcha_resp.value = '';
      this.$refs.recaptcha.reset();
    }

    const setPlace = (event) => {
      geoLocation.initLatLng(
        event.geometry.location.lat(),
        event.geometry.location.lng()
      );
      geoLocation.updateCenter(event.geometry.location);
      zoom.value = 15;
    }

    return {
      ...locationDropdown,
      ...geoLocation,
      ...registerSalon,
      media,
      mediaSelected,
      isLoading,
      showDialog,
      defaultFormikClass,
      uploadImage,
      backToLandingPage,
      submitRegisterForm,
      isDataValid,
      buildForm,
      register,
      captcha_resp,
      onVerifyCapthca,
      onExpiredCapthca,
      isAgree,
      setPlace,
      zoom,
      recaptcha
    };
  },
};
</script>

<style scoped>
#hero {
  height: 70vh;
}
</style>
